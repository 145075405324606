import styled from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'

export const FormContainer = styled.div`
  margin: 20px 0 0;
  font-family: 'Roboto';
  font-size: 0.9rem;
  .row {
    margin: 10px auto;
  }
  .buttons {
    justify-content: center;
    ${media.sm`  
      justify-content: flex-end;
    `};
  }
  ${media.sm`  
    margin: 20px 30px 0;
  `};
`
export const HistoryHeader = styled.div`
  margin: 20px 20px 0;
  ${media.sm`  
    margin: 20px 30px 0;
  `};
  .title {
    font-family: 'DroidSerif';
    font-size: 1.8rem;
    color: #c59d5f;
    display: none;
    ${media.sm`  
      display:block;
      margin: 20px 0 0;
    `};
  }
  .subtitle {
    font-family: 'Roboto';
    font-weight: 900;
    color: ${theme.purpleBrown};
    letter-spacing: 1.08px;
    font-size: 0.9rem;
    text-transform: uppercase;
    padding: 21px 0 10px;
  }
  .select {
    width: 75%;
    ${media.sm`  
      width: 25%;
    `};
  }
`
export const NoneSection = styled.div`
  margin: 20px 30px 0;
  font-family: 'Roboto';
`
interface PurchaseSectionProps {
  status?: string
}
export const PurchaseSection = styled.div<PurchaseSectionProps>`
  .customeTip {
    color: white !important;
    background-color: #c59d5f !important;
    &.place-top {
      &:after {
        border-top-color: #c59d5f !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
      }
    }
  }
  border: 1px solid #cbcbcb;
  font-family: 'Roboto';
  margin: 20px 20px 0;
  .hide {
    display: none;
    ${media.md`  
      display:block;
    `};
  }
  .mobile-hide {
    display: block;
    ${media.md`  
      display:none;
    `};
  }
  ${media.sm`  
    margin: 20px 30px 0;
  `};
  .oval {
    width: 12px;
    height: 12px;
    background-color: #ffc323;
    border-radius: 50%;
    ${props => {
      if (props.status == 'Aprobada' || props.status == 'Confirmada') {
        return `
          background-color: #6ac665;
        `
      }

      if (props.status == 'Rechazada' || props.status == 'Cancelada') {
        return `
          background-color: #ff4040;
        `
      }

      return null
    }};
  }
  .event-picture {
    display: none;
    margin: auto;
    img {
      width: 100%;
      max-width: 150px;
      height: 105px;
      &.logo {
        max-width: 150px;
        height: 150px;
        border: solid 1px #dfdfdf;
      }
    }
    ${media.sm`  
      display: block;
    `};
  }
  .title {
    font-weight: 900;
    font-size: 0.9rem;
    text-transform: uppercase;
    &.camel {
      color: #c59d5f;
      font-size: 1.1rem;
      ${media.sm`  
        font-size: 1.7rem;
      `};
      font-family: 'DroidSerif';
    }
    &.colored {
      color: #c59d5f;
    }
  }
  .split {
    display: inline-block;
    width: 50%;
    ${media.sm`  
      display: block;
      width: 100%;
    `};
  }
  .subtitle {
    font-weight: normal;
    font-size: 0.8rem;
    &.camel {
      color: #c59d5f;
      font-size: 1.1rem;
    }
    &.colored {
      color: #c59d5f;
    }
  }
  .header {
    width: 100%;
    margin: auto;
    padding: 10px;
    background-color: #f4f4f4;
    .ovalContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .rowContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-end;
    }
    .nopadding {
      padding: 0 5px;
      .arrow-pointer {
        cursor: pointer;
      }
    }
  }
  .detail {
    margin: auto;
    padding: 20px 0;
    margin: auto 15px;
    ${media.sm`  
      margin: auto 30px;
    `};
    &.border {
      border-bottom: 1px solid #dfdfdf;
    }
    .col-lg-2 {
      padding-right: 0;
      padding-left: 0;
    }
    .col-md-2 {
      padding-right: 0;
      padding-left: 0;
    }
    .col-lg-3 {
      &.event-picture {
        padding-right: 10px;
      }
      padding-right: 0;
      padding-left: 0;
    }
    .col-md-3 {
      &.event-picture {
        padding-right: 10px;
      }
      padding-right: 0;
      padding-left: 0;
    }
    .no-padding {
      padding: 0;
    }
    &.no-padding {
      padding: 0 0 10px;
      color: ${theme.purpleBrown};
      letter-spacing: 1px;
      font-size: 1rem;
      font-weight: 900;
      span {
        font-weight: 500;
      }
    }
  }
`
export const StyledLabel = styled.label`
  color: ${theme.purpleBrown};
  margin: 0;
  margin-right: 1rem;
  font-size: 0.9rem;
  font-weight: 300;
  font-family: 'Roboto';
  margin-bottom: 5px;
  flex: 100%;
  padding-bottom: 5px;
  ${media.sm`  
    margin-bottom:0; 
    flex: 1 0 auto;

  `};
`
interface StyledInputProps {
  error?: boolean
}

export const StyledInput = styled.input<StyledInputProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #cbcbcb;
  font-family: 'Roboto';
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  line-height: normal;
  letter-spacing: 1px;
  font-family: 'Roboto';
  &:focus {
    border-color: #c59d5f;
    outline: none;
    box-shadow: 0px 0px 2px 0px #c59d5f;
  }
  &::placeholder {
    color: #cbcbcb;
  }

  ${props => {
    if (props.error) {
      return `
        border-color: red;
      `
    }

    return null
  }};

  width: 100%;
`
interface formProps {
  display: boolean
}
export const AuthForm = styled.form`
  display: ${(props: formProps) => (props.display ? 'block' : 'none')};
  padding: 30px 7rem;
  h1 {
    text-align: left;
    font-family: DroidSerif;
  }
  p.link {
    text-align: right;
    margin-top: 1rem;
    margin-bottom: 1rem;
    a {
      color: #000000;
      font-size: 1rem;
      font-weight: 300;
      font-family: 'Roboto';
      font-style: normal;
      font-stretch: normal;
      text-decoration: underline;
      letter-spacing: 1px;
    }
  }
  .register-text {
    font-family: 'San Francisco Display';
    text-align: center;
    letter-spacing: 1px;
    font-weight: 900;
    color: #000000;
  }
  .password-text {
    padding-bottom: 2rem;
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: var(--purple-brown);
  }
  .secondary-btn-container {
    padding: 1rem 0 1rem 0;
    .secondary-btn-brown {
      background: white;
      border: solid 1px ${theme.secondaryColor};
      color: ${theme.secondaryColor};
      &:hover {
        background: ${theme.secondaryColor};
        color: white;
      }
    }
    #cancel-btn {
      background: white;
      border: solid 1px #000000;
      color: #000000;
      &:hover {
        border: solid 1px ${theme.secondaryColor};
        background: ${theme.secondaryColor};
        color: white;
      }
    }
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 1rem;
    p.link {
      text-align: center;
    }
  }
  @media screen and (min-width: 451px) and (max-width: 767px) {
    padding: 5rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 38px 8rem;
  }
  @media screen and (min-width: 1101px) {
    padding: 25px 7rem;
  }
`
