import React, { Component } from 'react'
import AuthContext from '../../context/auth-context'
import ProfileSection from '../ProfileSection'
import { navigate } from '@reach/router'

import { Row, Column, Ul, Li, Avatar } from './style'

class Profile extends Component<{ handleCurrent: any; current: any }> {
  state = {
    current: -1
  }

  componentDidMount() {
    this.handleClick(0)
  }

  handleClick = (index: any) => {
    this.setState({ current: index })
    this.props.handleCurrent(index)
  }

  render() {
    const { handleClick } = this
    return (
      <React.Fragment>
        <Row className="row">
          <Column className="bigtitle col-12">Mi cuenta</Column>
        </Row>
        <Row className="row content">
          <Column className="col-12 col-lg-3 d-flex flex-column">
            <div className="box">
              <AuthContext.Consumer>
                {(context: any) => {
                  return context.user ? (
                    <Avatar>
                      <div>
                        {context.user.name.charAt(0)} {context.user.last_name.charAt(0)}
                      </div>
                      <p className="name">
                        {context.user.name} {context.user.last_name}
                      </p>
                      <p className={this.props.current != 0 ? 'hide' : ''}>Miembro desde</p>
                      <p className={this.props.current != 0 ? 'bottomline hide' : 'bottomline'}>
                        {context.user.created_at}
                      </p>
                    </Avatar>
                  ) : (
                    <Avatar></Avatar>
                  )
                }}
              </AuthContext.Consumer>
              <Ul>
                <Li
                  className={this.props.current == 1 ? 'active topline hide' : 'topline hide'}
                  onClick={() => handleClick(1)}
                  current={this.props.current}
                >
                  <i className="icon-perfil_usuario item-space" /> Datos Personales
                </Li>
                <Li
                  className={this.props.current == 2 ? 'active hide' : 'hide'}
                  onClick={() => handleClick(2)}
                  current={this.props.current}
                >
                  <i className="icon-perfil_historial item-space" /> Historial de compras
                </Li>
                <Li
                  className={this.props.current == 3 ? 'active hide' : 'hide'}
                  onClick={() => handleClick(3)}
                  current={this.props.current}
                >
                  <i className="icon-perfil_reservas item-space" /> Mis reservas
                </Li>
                <Li
                  className={this.props.current == 4 ? 'active hide' : 'hide'}
                  onClick={() => handleClick(4)}
                  current={this.props.current}
                >
                  <i className="icon-perfil_ocntrasena item-space" /> Cambiar contraseña
                </Li>
                <AuthContext.Consumer>
                  {(context: any) => {
                    return (
                      <Li
                        className={
                          (this.props.current == 5 ? 'active' : '') +
                          (this.props.current != 0 ? 'line' : '')
                        }
                        onClick={context.logOut()}
                        current={this.props.current}
                      >
                        <i className="icon-perfil_cerrar_sesion item-space" /> Cerrar sesión
                      </Li>
                    )
                  }}
                </AuthContext.Consumer>
              </Ul>
            </div>
          </Column>
          <Column className="col-12 col-lg-9 content" current={this.props.current}>
            <div className="box">
              <ProfileSection current={this.props.current} />
            </div>
          </Column>
        </Row>
      </React.Fragment>
    )
  }
}

export default Profile
