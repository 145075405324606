import React, { Component } from 'react'
import axios from 'axios'
import { Collapse } from 'react-collapse'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import Loading from '../../Loading'

import { PurchaseSection, HistoryHeader, NoneSection } from '../style'
class Reservations extends Component<{ customer: any }, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      reservations: [],
      currentEvent: 0,
      years: this.generateYears(),
      loading: true
    }
    this.getReservations(this.state.years[0].value)
  }

  generateYears = () => {
    const years: any[] = []
    const baseYear = 2019
    const currentYear = new Date().getFullYear()

    for (let i = currentYear; i >= baseYear; i--) {
      years.push({ value: i, label: i })
    }
    return years
  }

  getReservations(selectedYear: number) {
    const customerId = this.props.customer.customerId
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
    }

    axios
      .get(`${process.env.API_URL}customer/reservations/${customerId}/${selectedYear}`, { headers })
      .then((data: any) => this.setState({ reservations: data.data }))
      .then(() => this.setState({ loading: false }))
      .catch((error: any) => {
        console.log(error)
      })
  }

  handleArrowClick = (index: any) => this.setState({ currentEvent: index })

  handleYear = (selectedYear: any) => this.getReservations(selectedYear.value)

  render() {
    const { reservations, years, loading, currentEvent } = this.state
    return loading === true ? (
      <Loading />
    ) : (
      <React.Fragment>
        <HistoryHeader>
          <div className="title">Mis reservas</div>
          <div className="subtitle">reservas realizadas en</div>
          <div className="select">
            <Select
              isSearchable={false}
              className="select-control-container"
              classNamePrefix="select-control"
              name="city"
              defaultValue={years[0]}
              options={years}
              onChange={this.handleYear}
            />
          </div>
        </HistoryHeader>
        {reservations && reservations.length > 0 ? (
          reservations.map((reservation: any, index: number) => {
            const dateandtime = new Date(reservation.reservation_date)
            const date =
              dateandtime.getFullYear() + '-' + dateandtime.getMonth() + '-' + dateandtime.getDay()
            const time =
              dateandtime.getHours() +
              ':' +
              (dateandtime.getMinutes() < 10 ? '0' : '') +
              dateandtime.getMinutes()
            return (
              <PurchaseSection key={index} status={reservation.status}>
                <div className="row header">
                  <div className="col-1 col-lg-1 nopadding ovalContainer">
                    <div className="oval" data-tip data-for={reservation.status}></div>
                    <ReactTooltip
                      place="top"
                      className="customeTip"
                      effect="solid"
                      id={reservation.status}
                    >
                      <span>{reservation.status}</span>
                    </ReactTooltip>
                  </div>
                  <div className="col-10 col-lg-3 nopadding">
                    <div className="title">Lugar</div>
                    <div className="subtitle">{reservation.restaurant_name}</div>
                  </div>
                  <div className="col-lg-2 hide">
                    <div className="title">Fecha</div>
                    <div className="subtitle">{date}</div>
                  </div>
                  <div className="col-lg-2 hide">
                    <div className="title">Hora</div>
                    <div className="subtitle">{time}</div>
                  </div>
                  <div className="col-lg-3 hide">
                    <div className="title">Número de personas</div>
                    <div className="subtitle">{reservation.persons_number}</div>
                  </div>
                  <div className="col-1 col-lg-1 nopadding rowContainer">
                    <i
                      className={
                        this.state.currentEvent === index
                          ? 'icon-grande-table-32'
                          : 'icon-grande-table-10'
                      }
                      onClick={() => this.handleArrowClick(index)}
                    />
                  </div>
                </div>

                <Collapse isOpened={this.state.currentEvent === index ? true : false}>
                  <div className="row detail">
                    <div className="col-lg-3 event-picture">
                      <img src={reservation.restaurant_icon} className="logo" />
                    </div>
                    <div className="col-lg-9 no-padding">
                      <div className="title camel">{reservation.restaurant_name}</div>
                      <div className="subtitle">Restaurante {reservation.alias}</div>
                    </div>
                  </div>
                  <div className="row detail no-padding">
                    <div className="col-12 no-padding">
                      <div className="subtitle no-padding">
                        <i className="icon-grande-table-26"></i> Dirección: {reservation.address}
                      </div>
                    </div>
                  </div>
                </Collapse>
              </PurchaseSection>
            )
          })
        ) : (
          <NoneSection className="item-prop col">No tienes compras realizadas</NoneSection>
        )}
      </React.Fragment>
    )
  }
}

export default Reservations
