import React from 'react'
import { LoadingImg } from '../../assets/images/general'
import { StyledLoading } from './style'

const Loading = () => (
  <StyledLoading>
    <img src={LoadingImg} />
  </StyledLoading>
)

export default Loading
