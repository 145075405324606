import styled from 'styled-components'
import media from '../../styles/media'
import DatePicker from 'react-datepicker'
import theme from '../../styles/theme'
const background = require('../../assets/images/pages/bg-cart.png')

interface CustomProps {
  current?: number
}

export const Column = styled.div<CustomProps>`
  .box {
    background-color: #fff;
    color: black;
    margin: 5px 0;
    padding: 6% 2%;
    font-family: Roboto;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    ${media.sm`  
      margin: 10px;
    `};
  }
  &.content {
    @media screen and (max-width: 767px) {
      ${props => {
        if (props.current == 0) {
          return `
            display: none;
          `
        }

        return null
      }};
    }
  }
`
export const Row = styled.div`
  margin-top: 0;
  align-items: center;
  align-items: flex-start;
  ${media.sm`  
    margin-top: 10%;
  `};
  &.content {
    margin-top: 20px;
  }
  .bigtitle {
    display: none;
    margin: 10px;
    font-family: 'DroidSerif';
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    ${media.sm`  
      display: block;
    `};
  }
`
interface LiProps {
  current?: number
}
export const Li = styled.li<LiProps>`
  font-family: Roboto;
  font-size: 1rem;
  list-style-type: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.43;
  padding: 5px;
  i {
    font-size: 1.5rem;
  }
  &.active {
    color: #c59d5f;
  }

  &:hover {
    cursor: pointer;
  }

  &.topline {
    border-top: 1px solid #dfdfdf;
    padding-top: 14px;
  }
  &.line {
    border-top: 1px solid #dfdfdf;
    ${media.sm`  
      border-top: 0px solid #dfdfdf;
    `};
  }
  &.hide {
    @media screen and (max-width: 767px) {
      ${props => {
        if (props.current != 0) {
          return `
            display: none;
          `
        }

        return null
      }};
    }
  }
`

export const Avatar = styled.div`
  font-family: 'Roboto';
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #c59d5f;
  div {
    display: inline-block;
    border: solid 3px #c59d5f;
    background-color: ${theme.purpleBrown};
    font-size: 3.2rem;
    padding: 7%;
    border-radius: 50%;
    margin: auto;
    font-style: normal;
    letter-spacing: normal;
    font-weight: 900;
  }
  p {
    font-size: 0.8rem;
    font-weight: normal;
    color: #000000;
    padding: 0;
    margin-bottom: 0;
    &.name {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.25;
      padding: 15px;
    }
    &.bottomline {
      padding-bottom: 14px;
    }
    &.hide {
      display: none;
      ${media.sm`  
        display:block;
      `};
    }
  }
`

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  width: fit-content;
  margin: auto;
`
