import styled from 'styled-components'
import theme from '../../styles/theme'
import media from '../../styles/media'

interface formProps {
  display: boolean
}

export const AuthForm = styled.form`
  display: ${(props: formProps) => (props.display ? 'block' : 'none')};
  padding: 30% 2rem;
  h1 {
    text-align: left;
    font-family: DroidSerif;
  }
  p.link {
    text-align: right;
    margin-top: 1rem;
    margin-bottom: 1rem;
    a {
      color: #000000;
      font-size: 1rem;
      font-weight: 300;
      font-family: 'Roboto';
      font-style: normal;
      font-stretch: normal;
      text-decoration: underline;
      letter-spacing: 1px;
    }
  }
  .register-text {
    font-family: 'San Francisco Display';
    text-align: center;
    letter-spacing: 1px;
    font-weight: 900;
    color: ${theme.purpleBrown};
  }
  .password-text {
    padding-bottom: 2rem;
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: ${theme.purpleBrown};
  }
  .secondary-btn-container {
    padding: 1rem 0 1rem 0;
    .secondary-btn-brown {
      font-size: 13px;
      background: white;
      letter-spacing: 0.75px;
      border: solid 1px ${theme.secondaryColor};
      color: ${theme.secondaryColor};
      &:hover {
        background: ${theme.secondaryColor};
        color: white;
      }
    }
    #cancel-btn {
      background: white;
      border: solid 1px #000000;
      color: #000000;
      &:hover {
        border: solid 1px ${theme.secondaryColor};
        background: ${theme.secondaryColor};
        color: white;
      }
    }
  }
  ${media.sm`
    padding: 50% 2rem;
  `}
  @media screen and (max-width: 450px) {
    padding: 2rem 1rem;
    p.link {
      text-align: center;
    }
  }
  @media screen and (min-width: 451px) and (max-width: 767px) {
    padding: 2rem;
  }
`
export const FormInputLoginContainer = styled.div`
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`
export const StyledLabel = styled.label`
  color: ${theme.purpleBrown};
  margin: 0;
  margin-right: 1rem;
  font-size: 13px;
  font-weight: 300;
  font-family: 'Roboto';
  font-style: normal;
  font-stretch: normal;
  margin-bottom: 5px;
  letter-spacing: 1px;
  line-height: normal;
  &.padding-bottom {
    padding-bottom: 1rem;
  }
`
export const LoginDecorator = styled.div`
  &.row {
    margin: 0;
    .line {
      width: 46%;
      height: 2px;
      border-top-color: #cbcbcb;
      border-top-width: 2px;
      border-top-style: solid;
      margin: 2.8rem 0;
    }
    @media screen and (max-width: 450px) {
      .line {
        width: 45%;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      .line {
        width: 43%;
      }
    }
    div {
      padding-top: 2rem;
      i {
        color: #c59d5f;
        font-size: 1.1rem;
        background-color: #fff;
        z-index: 1;
      }
    }
  }
`
export const SubmitButton = styled.input`
  outline: none;
  background: ${theme.secondaryColor};
  border: solid 1px ${theme.secondaryColor};
  padding: 0.5rem 2.5rem;
  color: white;
  text-transform: uppercase;
  border-radius: 17.5px;
  letter-spacing: 1.7px;
  display: block;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background: white;
    color: ${theme.secondaryColor};
  }
  @media screen and (min-width: 801px) and (max-width: 1100px) {
    display: inline-block;
  }
`
export const BackButton = styled.button`
  font-size: 17px;
  font-family: DroidSerif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.purpleBrown};
  border: none;
  background: white;
  display: inherit;
  padding: 0 0 10px 0;
  position: relative;
  z-index: 98;

  ${media.md`  
  position: absolute;
  top: 2rem;
  z-index: 99;
`};
`
