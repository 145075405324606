import styled from 'styled-components'
import media from '../../styles/media'
import { urlResolve } from 'gatsby-core-utils'
const background = require('../../assets/images/pages/bg-cart.png')

interface SectionProps {
  color: string
}

interface InfoProps {
  contrast?: boolean
}

export const Wrapper = styled.div`
  background-image: url(${background});
  background-size: 0%;
  ${media.sm`  
    background-size: 100% 300px;
    background-repeat: no-repeat;
    background-position: center 0;
  `};
  ${media.md`  
    background-size: 100% 40vh;
    background-repeat: no-repeat;
    background-position: center 0;
  `};
  max-width: 100%;
  width: 100%;
  padding-bottom: 30px;
  .head {
    &.hide {
      display: none;
    }
    display: block;
    background-image: url(${background});
    background-size: cover;
    padding: 30px 15px;
    .pl-0 {
      padding-left: 0;
    }
    ${media.sm`  
      display:none;
    `};

    .title {
      padding-top: 20px;
      font-family: DroidSerif;
      font-size: 1.4rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
  .link {
    font-family: Roboto;
    font-size: 0.8rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.08px;
    color: #c59d5f;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const StyledRow = styled.div``

export const BenefitText = styled.h1`
  margin-top: 1rem;
  color: #c59d5f;
  font-weight: normal;
  font-family: 'DroidSerif';
  ${media.sm`  
    margin-top: 0;
  `};
`

export const StyledBenefitBar = styled.div`
  padding-top: 0;
  ${media.md`  
    padding-top: 2rem;
  `};
`
export const StyledRowBenefitBar = styled.div`
  align-items: center;
`

export const ButtonBenefitContainer = styled.div`
  display: none;
  ${media.md`  
    display: block;
    text-align: right;
  `};
`

export const StyledSection = styled.div`
  background: ${(props: SectionProps) => props.color};
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`
export const SectionImage = styled.img`
  max-width: 100%;
  margin: auto;
  order: 1;
  ${media.sm`  
  order:unset;
  `};
`
export const InfoBenefitContainer = styled.div<InfoProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  color: ${(props: InfoProps) => (props.contrast === true ? 'white' : '#000')};
  order: 2;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  ${media.sm`  
    text-align: left;
    order:unset;
  `};
`

export const IconContainer = styled.div``
export const StyledIcon = styled.img`
  max-width: 50px;
  height: 50px;
`
export const SecondaryTitle = styled.h4`
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: 1.2px;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 5px;
  color: inherit;
  font-size: 1.1rem;
  color: ${(props: InfoProps) => (props.contrast === true ? '#e8e8e8' : '#666666')};
`
export const PrimaryTitle = styled.h2`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.67;
  letter-spacing: 2.3px;
  text-transform: uppercase;
  font-size: 2.1rem;
`
export const Description = styled.p`
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 1.2px;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const ButtonContainer = styled.div`
  padding-top: 2rem;
  text-align: center;
  padding-bottom: 2rem;
`

export const StyledBold = styled.span`
  font-weight: 700;
`
