import styled from 'styled-components'
import media from '../../styles/media'

export const StyledLoading = styled.div`
  height: 100%;
  min-height: 28vh;
  width: 100%;
  background: transparent;
  flex: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  ${media.sm`
  min-height: 53vh;
  `}
  ${media.lg`
  min-height: 60vh;
  `}
`
