import React, { Component } from 'react'
import Layout from '../components/Layout'
import Profile from '../components/Profile'
import { Wrapper, Container } from '../components/StylesPages/profileStyle'
class profilepage extends Component {
  state = {
    title: '',
    current: -1
  }

  handleCurrent = (current: any) => {
    this.setState({ current })
    switch (current) {
      case 1:
        this.setState({ title: 'Datos personales' })
        break
      case 2:
        this.setState({ title: 'Historial de compras' })
        break
      case 3:
        this.setState({ title: 'Mis reservas' })
        break
      case 4:
        this.setState({ title: 'Contraseña' })
        break
      default:
        break
    }
  }

  render() {
    return (
      <Layout themeType="dark" currentPage={'benefits'}>
        <Wrapper>
          <div className={`${this.state.current == 0 ? 'hide ' : ''}row head`}>
            <div className="container pl-0">
              <div className="col-12 link" onClick={() => this.handleCurrent(0)}>
                <i className="icon-grande-table-13"></i> Regresar al menú
              </div>
              <div className="col-12 title">{this.state.title}</div>
            </div>
          </div>
          <Container className="container">
            <Profile handleCurrent={this.handleCurrent} current={this.state.current}></Profile>
          </Container>
        </Wrapper>
      </Layout>
    )
  }
}

export default profilepage
