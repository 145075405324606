import React, { Component } from 'react'
import axios from 'axios'
import { Collapse } from 'react-collapse'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import Loading from '../../Loading'

import { PurchaseSection, HistoryHeader, NoneSection } from '../style'

const PurchaseItems = (rejectedItems: any) => (
  <div>
    {rejectedItems && rejectedItems.length > 0 ? (
      rejectedItems.map((rejectedItem: any, index: number) => {
        return (
          <div className="row detail border" key={index}>
            <div key={index} className="col-md-3 event-picture">
              <img src={rejectedItem.item.image} />
            </div>
            <div className="col-md-3">
              <div className="title hide">{rejectedItem.item_type}</div>
              <div className="subtitle camel">{rejectedItem.description}</div>
            </div>
            <div className="col-md-2 mobile-hide">
              <div className="title split">REFERENCIA</div>
              <div className="title split">{rejectedItem.purchase_transaction_id}</div>
            </div>
            <div className="col-md-2">
              <div className="title split">CANTIDAD</div>
              <div className="title split">{rejectedItem.quantity}</div>
            </div>
            <div className="col-md-2 hide">
              <div className="title">VALOR UNITARIO</div>
              <div className="title">
                ${' '}
                {Number(rejectedItem.unit_price)
                  .toFixed(2)
                  .replace('.', ',')}
              </div>
            </div>
            <div className="col-lg-2">
              <div className="title split">SUBTOTAL</div>
              <div className="title split">
                ${' '}
                {Number(rejectedItem.subtotal)
                  .toFixed(2)
                  .replace('.', ',')}
              </div>
            </div>
          </div>
        )
      })
    ) : (
      <div></div>
    )}
  </div>
)

class History extends Component<{ customer: any }, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      purchases: [],
      currentEvent: '0-0',
      years: this.generateYears(),
      loading: true
    }
    this.getPurchases(this.state.years[0].value)
  }

  generateYears = () => {
    const years: any[] = []
    const baseYear = 2019
    const currentYear = new Date().getFullYear()

    for (let i = currentYear; i >= baseYear; i--) {
      years.push({ value: i, label: i })
    }
    return years
  }

  getPurchases(selectedYear: number) {
    const customerId = this.props.customer.customerId
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
    }

    axios
      .get(`${process.env.API_URL}customer/transactions/history`, {
        headers,
        params: { customerId, year: selectedYear }
      })
      .then((data: any) => this.setState({ purchases: data.data, loading: false }))
      .catch((error: any) => {
        console.log(error)
      })
  }

  handleArrowClick = (index: any) => this.setState({ currentEvent: index })

  handleYear = (selectedYear: any) => this.getPurchases(selectedYear.value)

  render() {
    const { purchases, years, loading, currentEvent } = this.state
    return loading === true ? (
      <Loading />
    ) : (
      <React.Fragment>
        <HistoryHeader>
          <div className="title">Historial de compras</div>
          <div className="subtitle">compras realizadas en</div>
          <div className="select">
            <Select
              isSearchable={false}
              className="select-control-container"
              classNamePrefix="select-control"
              name="city"
              defaultValue={years[0]}
              options={years}
              onChange={this.handleYear}
            />
          </div>
        </HistoryHeader>
        {purchases && purchases.length > 0 ? (
          purchases.map((transaction: any, subindex: number) => {
            const dateandtime = new Date(transaction.created_at)
            const date =
              dateandtime.getFullYear() +
              '-' +
              (dateandtime.getMonth() + 1) +
              '-' +
              dateandtime.getDate()
            return (
              <PurchaseSection status={transaction.status} key={subindex}>
                <div className="row header">
                  <div className="col-1 col-md-1 nopadding ovalContainer">
                    <div className="oval" data-tip data-for={transaction.status}></div>
                    <ReactTooltip
                      place="top"
                      className="customeTip"
                      effect="solid"
                      id={transaction.status}
                    >
                      <span>{transaction.status}</span>
                    </ReactTooltip>
                  </div>
                  <div className="col-5 col-md-3 nopadding">
                    <div className="title">Fecha de compra</div>
                    <div className="subtitle">{date}</div>
                  </div>
                  <div className="col-5 col-md-2 nopadding hide">
                    <div className="title">Referencia</div>
                    <div className="subtitle">{transaction.id}</div>
                  </div>
                  <div className=" col-1 col-md-3 hide">
                    <div className="title">Enviado a</div>
                    <div className="subtitle">
                      {this.props.customer.name} {this.props.customer.last_name}
                    </div>
                  </div>
                  <div className="col-4 col-md-2 nopadding">
                    <div className="title colored">Total</div>
                    <div className="subtitle colored">
                      ${' '}
                      {Number(transaction.total)
                        .toFixed(2)
                        .replace('.', ',')}
                    </div>
                  </div>
                  <div className="col-1 col-md-1 nopadding rowContainer">
                    {currentEvent === subindex ? (
                      <div>
                        <i className="icon-grande-table-32" />
                      </div>
                    ) : (
                      <div className="arrow-pointer">
                        <i
                          className="icon-grande-table-10"
                          onClick={() => this.handleArrowClick(subindex)}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <Collapse isOpened={currentEvent === subindex ? true : false}>
                  {PurchaseItems(transaction.items)}
                </Collapse>
              </PurchaseSection>
            )
          })
        ) : (
          <NoneSection className="item-prop col">No tienes compras realizadas</NoneSection>
        )}
      </React.Fragment>
    )
  }
}

export default History
