import React, { Component } from 'react'
import AuthContext from '../../context/auth-context'
import Form from './components/Form'
import History from './components/History'
import Password from './components/Password'
import { navigate } from '@reach/router'
import Reservations from './components/Reservations'
import { withPrefix } from 'gatsby'

class ProfileSection extends Component<{ current: number }, any> {
  state = {
    customer: '',
    activeStep: 0
  }

  componentDidMount() {
    const { user, getUser } = this.context
    if (!user) {
      navigate(withPrefix('/login'))
      return
    }

    const customer = getUser()
    if (customer) {
      this.setState({ customer })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.current == 1 || this.props.current == 0 ? (
          <Form customer={this.state.customer}></Form>
        ) : this.props.current == 2 ? (
          <History customer={this.state.customer}></History>
        ) : this.props.current == 3 ? (
          <Reservations customer={this.state.customer}></Reservations>
        ) : this.props.current == 4 ? (
          <Password customer={this.state.customer}></Password>
        ) : (
          <React.Fragment>sdfsdfsdf</React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

ProfileSection.contextType = AuthContext

export default ProfileSection
