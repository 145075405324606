import React, { Component } from 'react'
import axios from 'axios'
import {
  FormInputLoginContainer,
  StyledLabel,
  SubmitButton,
  LoginDecorator
} from '../../StylesPages/loginStyle'
import { TitleCard } from '../../StylesPages/membershipStyle'
import { InputContainer, StyledInput, StyledError, CustomButton } from '../../Collapse/stepped/style'
import { ButtonContainer } from '../../Button/style'
import { AuthForm } from '../style'

class Password extends Component<{ customer: any }, any> {
  MESSAGE = 'Por favor dirígete a la página de inicio de sesión.'
  constructor(props: any) {
    super(props)
    this.state = {
      showForm: true,
      message: ``,
      fields: {},
      errors: {}
    }
  }
  handleUpdate = (field: string, event: any) => {
    let fields = this.state.fields
    fields[field] = event.target.value
    this.setState({ fields })
  }

  handleSubmit(event: any) {
    const { id } = this.props.customer
    const { password } = this.state.fields
    event.preventDefault()
    if (this.handleValidation()) {
      if (id) {
        this.updatePassword(id, password)
        return
      }
      this.setState({
        message: `No existe un usuario para generar la contraseña. Por favor dirígete a la página de inicio de sesión e intenta nuevamente.`
      })
    }
  }
  updatePassword(id: number, password: string) {
    axios
      .post(`${process.env.API_URL}customer/update-password`, { id, password }, {})
      .then(() => {
        this.setState({
          showForm: false,
          message: `Tu contraseña fue actualizada correctamente.`
        })
      })
      .catch(error => {
        this.setState({
          showForm: false,
          message: `Usuario no encontrado. ${error}`
        })
      })
  }

  handleValidation = () => {
    let fields = this.state.fields
    let errors: any = {}
    let formIsValid = true

    if (!fields['password']) {
      formIsValid = false
      errors['password'] = 'Contraseña requerida'
    }

    if (typeof fields['password'] !== 'undefined') {
      if (fields['password'].length < 8) {
        formIsValid = false
        errors['password'] = 'La contraseña debe tener mínimo 8 caracteres'
      }
      let count = 0
      count += /[a-z]/.test(fields['password']) ? 1 : 0
      count += /[A-Z]/.test(fields['password']) ? 1 : 0
      if (count < 2) {
        formIsValid = false
        errors['password'] = 'La contraseña debe tener letras minúsculas y mayúsculas'
      }
    }

    if (!fields['repeatPassword']) {
      formIsValid = false
      errors['repeatPassword'] = 'Debes repetir tu contraseña'
    }

    if (
      typeof fields['repeatPassword'] !== 'undefined' &&
      typeof fields['password'] !== 'undefined'
    ) {
      if (fields['repeatPassword'] != fields['password']) {
        formIsValid = false
        errors['repeatPassword'] = 'Las contraseñas no coinciden'
      }
    }

    this.setState({ errors })
    return formIsValid
  }

  render() {
    const { errors, fields } = this.state
    return (
      <React.Fragment>
        {this.state.showForm ? (
          <AuthForm
            display={true}
            method="post"
            onSubmit={(event: any) => {
              this.handleSubmit(event)
            }}
          >
            <TitleCard className="center-title">Cambiar contraseña</TitleCard>
            <FormInputLoginContainer>
              <StyledLabel>Contraseña segura</StyledLabel>
              <InputContainer>
                <StyledInput
                  placeholder="Ingresa la contraseña"
                  error={errors['password']}
                  type="password"
                  name="password"
                  onChange={this.handleUpdate.bind(this, 'password')}
                  value={fields['password']}
                />
                {errors['password'] && <StyledError>{errors['password']}</StyledError>}
              </InputContainer>
            </FormInputLoginContainer>
            <FormInputLoginContainer>
              <StyledLabel>Repite la contraseña segura</StyledLabel>
              <InputContainer>
                <StyledInput
                  placeholder="Repite la contraseña"
                  error={errors['repeatPassword']}
                  type="password"
                  name="repeatPassword"
                  onChange={this.handleUpdate.bind(this, 'repeatPassword')}
                  value={fields['repeatPassword']}
                />
                {errors['repeatPassword'] && <StyledError>{errors['repeatPassword']}</StyledError>}
              </InputContainer>
            </FormInputLoginContainer>
            <div className="password-text">
              La contraseña debe tener mínimo 8 caracteres, una letra mayúscula y una minúscula.
            </div>
            <ButtonContainer>
              <SubmitButton type="submit" value="Guardar" />
            </ButtonContainer>
          </AuthForm>
        ) : (
          <AuthForm display={true}>
            <TitleCard className="center-title">Cambiar contraseña</TitleCard>
            <div className="padding-bottom">{this.state.message}</div>
          </AuthForm>
        )}
      </React.Fragment>
    )
  }
}

export default Password
