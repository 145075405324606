import React, { Component } from 'react'
import moment from 'moment'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PurchaseSection, HistoryHeader, NoneSection, FormContainer, StyledLabel } from '../style'
import { StyledDatePicker } from '../../MembershipForm/style'

import { StyledInput, CustomButton, ButtonContainer } from '../../Collapse/stepped/style'
import ProfileSection from '..'

interface errorValues {
  errorName: string | null
  errorLastName: string | null
  errorPhone: string | null
  errorEmail: string | null
  errorBirthdate: string | null
}
class Form extends Component<{ customer: any }, any> {
  state = {
    errorName: null,
    errorLastName: null,
    errorPhone: null,
    errorEmail: null,
    errorBirthdate: null,
    name: this.props.customer.name,
    lastName: this.props.customer.last_name,
    ci: this.props.customer.identification,
    phone: this.props.customer.phone,
    email: this.props.customer.email,
    birthDate: this.props.customer.birthdate,
    message: ''
  }

  validate = () => {
    let result: boolean = true

    const errors: errorValues = {
      errorName: null,
      errorLastName: null,
      errorPhone: null,
      errorEmail: null,
      errorBirthdate: null
    }

    if (this.state.name.length < 1) {
      errors.errorName = 'Campo Requerido'
      result = false
    }

    if (this.state.lastName.length < 1) {
      errors.errorLastName = 'Campo Requerido'
      result = false
    }

    if (this.state.phone.length < 1) {
      errors.errorPhone = 'Campo Requerido'
      result = false
    }

    if (this.state.email.length < 1) {
      errors.errorEmail = 'Campo Requerido'
      result = false
    }

    if (!this.state.birthDate) {
      errors.errorBirthdate = 'Campo Requerido'
      result = false
    }

    this.setState(errors)
    return result
  }

  handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value })
  }
  handleLastnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: e.target.value })
  }
  handleCiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ci: e.target.value })
  }
  handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ phone: e.target.value })
  }
  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value })
  }
  handleBirthdateChange = (date: any) => {
    this.setState({ birthDate: date })
  }
  handleSave = () => {
    if (this.validate() !== false) {
      const { name, lastName, ci, phone, email, birthDate } = this.state
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
      axios
        .post(
          `${process.env.API_URL}customer/store-user-details`,
          {
            customerId: this.props.customer.customerId,
            name,
            lastName,
            ci,
            phone,
            email,
            birthDate
          },
          { headers }
        )
        .then((data: any) => {
          this.notify('Datos actualizados')
        })
        .catch((error: any) => {
          if (error.response) {
            const message = error.response.data.message
              ? error.response.data.message
              : 'Hubo un problema en la transacción'
            this.notify(message)
          } else {
            this.notify('Ha ocurrido un problema, intentelo más tarde')
          }
        })
    }
  }
  notify = (message: string, type?: string) => {
    if (type === 'error') {
      return toast.error(message)
    }
    return toast(message)
  }

  render() {
    const date = new Date(this.state.birthDate)
    return (
      <React.Fragment>
        <HistoryHeader>
          <div className="row no-side-margins">
            <div className="col">
              <div className="title">Datos personales</div>
            </div>
          </div>
        </HistoryHeader>
        <FormContainer>
          <div className="row no-side-margins top-margin">
            <div className="col-12 col-lg-6">
              <StyledLabel>Nombre</StyledLabel>
              <StyledInput
                name="firstName"
                type="text"
                error={this.state.errorName !== null}
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </div>
            <div className="col-12 col-lg-6">
              <StyledLabel>Apellido</StyledLabel>
              <StyledInput
                name="lastName"
                type="text"
                error={this.state.errorLastName !== null}
                value={this.state.lastName}
                onChange={this.handleLastnameChange}
              />
            </div>
          </div>
          <div className="row no-side-margins top-margin">
            <div className="col-12 col-lg-6">
              <StyledLabel>Numero de identificación</StyledLabel>
              <StyledInput
                name="identification"
                type="text"
                value={this.state.ci}
                onChange={this.handleCiChange}
                readOnly
              />
            </div>
            <div className="col-12 col-lg-6">
              <StyledLabel>Teléfono de contacto</StyledLabel>
              <StyledInput
                name="phone"
                type="text"
                error={this.state.errorPhone !== null}
                value={this.state.phone}
                onChange={this.handlePhoneChange}
              />
            </div>
          </div>
          <div className="row no-side-margins top-margin">
            <div className="col-12 col-lg-6">
              <StyledLabel>Correo electrónico</StyledLabel>
              <StyledInput
                name="email"
                type="text"
                error={this.state.errorEmail !== null}
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
            </div>
            <div className="col-12 col-lg-6">
              <StyledLabel>Fecha de nacimeinto</StyledLabel>
              <StyledDatePicker
                placeholderText="yyyy-mm-dd"
                dateFormat="yyyy-MM-dd"
                minDate={moment()
                  .subtract(99, 'years')
                  .toDate()}
                maxDate={moment()
                  .subtract(18, 'years')
                  .toDate()}
                selected={date}
                onChange={this.handleBirthdateChange}
                error={this.state.errorBirthdate !== null}
              />
            </div>
          </div>
          <div className="row no-side-margins top-margin buttons">
            <ButtonContainer>
              <CustomButton id="GRTB_CONT_DF" className="GRTB_SLEC_MEMB" onClick={this.handleSave}>
                Guardar cambios
              </CustomButton>
            </ButtonContainer>
          </div>
        </FormContainer>
        <ToastContainer
          position="bottom-right"
          autoClose={7000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          draggable={true}
          pauseOnHover
        />
      </React.Fragment>
    )
  }
}

export default Form
